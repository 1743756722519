<template>  
    <v-container :class="_class">
        <v-row no-gutters>
            <v-col :cols="imgCol">
                <div style="height: 180px; max-height': 180px;" :style="imageContainerStyle">
                    <div :style="imgStyle"></div>
                    <div class="gradient-background" style="position: absolute; height: 180px; top: 0; width: 100%;"></div>
                </div>
            </v-col>
            <v-col :cols="barCol"><div class="primary" :style="barStyle"></div></v-col>
        </v-row>
    </v-container>
</template>

<script>

const styles = {
    'right': {
        width: '4px',
        height: '100%',
        'min-height': '100%',
        'margin-right': '4px',
    }, 
    'bottom': {
        width: '100%',
        'min-width': '100%',
        height: '4px',
        'margin-top': '4px',
    }
}
/**
 * align: botton | right
 */
export default {
    props: ['src', 'align', '_class'],
    computed: {
        isBottomAlignment: function () {
            return this.align === 'bottom';
        },
        isRightAlignment: function () {
            return !this.isBottomAlignment;
        },
        barStyle: function () {
            return styles[this.align || 'right'];
        },
        imageContainerStyle: function () {
            if (this.isBottomAlignment) {
                return null;
            }
            return {
                'max-width': '98%', 'min-width': '98%' 
            }
        },
        imgStyle: function () {
            return {
                'background-image': "url('"+this.src+"')",
                'background-repeat': 'no-repeat',
                'background-position': 'center center', 
                'background-size': 'cover',
                'border-top-left-radius': this.isRightAlignment ? '8px' : '0',
                'border-bottom-left-radius': this.isRightAlignment ? '8px' : '0',
                height: '100%',

            }
        },
        imgCol: function () {
            return this.isBottomAlignment ? '12' : '11';
        },
        barCol: function () {
            return this.isBottomAlignment ? '12' : '1';
        }
    }
}
</script>