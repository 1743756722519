<template>
    <v-card
    width="350px"
    height="370px"
    class="secondary"
    dark>

    <MaskedImg :src="item.src" _class="pa-0" align="bottom"/>

    <v-card-text class="text--primary">
        <p class="text-primary text-center h4">{{ item.title }}</p>
        <p class="text-center">{{ item.date }}</p>
    </v-card-text>

    <div class="text-center">
        <v-btn small outlined rounded color="primary" :to="item.$route">
            <span class="text-primary">{{btnLabel}}</span>
        </v-btn>
    </div>
    <br>
  </v-card>
</template>
<script>
import MaskedImg from '../components/MaskedImg';
export default {
    props: {
        item: Object,
        btnLabel: {
            type: String,
            default: 'Pesquisar'
        }
    },
    components: {
        MaskedImg,
    }
}
</script>