<template>
    <div>
        <v-row class="mt-1x mb-1x" justify="center">
            <v-img v-if="page.banner" 
                height="400" 
                min-height="400" 
                :src="page.banner.src" />
        </v-row>
        
        <v-container >
            <v-row justify="center">
            
                <div class="width-secondary">
                    <HomeNewsHeader title="Notícias" height="80px" clipped clipclass="cliped-rb-path-px">
                        
                    </HomeNewsHeader >
                    <v-row justify="center">
                        <v-col cols="12" sm="6" md="4" v-for="post in homePageNews" :key="post.id"> 
                            <v-row
                                align="center"
                                justify="center" no-gutters>
                                <Card :item="post" btnLabel="Ver"/>
                            </v-row>
                        </v-col>
                    </v-row>

                    <br>
                    <!--div class="text-center" v-if="homePageNews && homePageNews.length > 0">
                        <v-btn :to="newsRoute" outlined rounded>
                            <font-awesome-icon icon="redo-alt" /> <span> Mostrar Mais</span>
                        </v-btn>
                    </div-->
                </div>
            </v-row>
        </v-container>
        <br><br>
    </div>
</template>

<script>
import HomeNewsHeader from '../components/HomeNewsHeader';


import Card from '../components/Card';
import { NEWS } from '../routes';

import { PAGE_KEYS } from '../utils';

import { mapActions, mapGetters } from 'vuex';

export default {
    data: () => ({
        items: [
            { id: 1, src: '/assets/img/banner/BANNERS HOMEPAGE_1.jpg' },
            { id: 2, src: '/assets/img/banner/BANNERS HOMEPAGE_2.jpg' },
            { id: 3, src: '/assets/img/banner/BANNERS HOMEPAGE_3.jpg' },
        ],
        backgound: '/assets/img/banner/background.png',
        page: {
            banner: null,
            text: null
        },
        loadingPage: false,
    }),
    created () {
        this.fetchNews({ limit: 3, });
        
    /*},
    mounted() {*/
        this.loadingPage = true;
        this.loadPage({ key : PAGE_KEYS.homePage }).then((function (page) {
            this.page = page;
        }).bind(this)).catch(() => {
        }).finally(() => this.loadingPage = false)
    },
    computed: {
        ...mapGetters('news', ['homePageNews']),
        newsRoute() {
            return NEWS;
        }
    },
    methods: { 
        ...mapActions('news', {
            fetchNews: 'list'
        }),
        ...mapActions(['loadPage']),
    },
    components: {
        HomeNewsHeader,
        Card,
        
    },
    
    
}
/**
 * 
 * 
 <v-carousel :style="{
                'background-image': `url('${backgound}')`, 
                'background-repeat': 'no-repeat',
                'background-size': 'cover',
                'min-height': '400px', 'max-width': '1700px'
            }" class="home-carousel" :show-arrows="false" cycle vertical-delimiters :vertical="true">
                <v-carousel-item 
                    style="height: 400px; min-height: 400px; max-width: 1700px" 
                    v-for="item in items" :key="item.id">
                    <v-img height="400" min-height="400" :src="item.src" aspect-ratio="1.7" contain/>    
                </v-carousel-item>
            </v-carousel>
 */
</script>